.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h2{
    margin: 10px 0 10px;
    font-size: 28px;
}

h2 span{
    font-size: 16px;
    font-weight: 300;
}

.header{
    width: 100%;
}

.header img{
    margin-top: 30px;
    max-width: 350px;
    height: auto;
    margin-bottom: 20px;
}

.MoviesHero{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: rgb(51,51,51);
    background: radial-gradient(circle, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 100%);
    width: 100vw;
    height: 60vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.MoviesHero p{
    font-weight: bold;
    color: white;
    text-shadow: 10px 10px 10px black;
    font-size: 50px;
    margin: 0;
    text-align: left;
    padding-left: 50px;
    margin-bottom: -60px;
    z-index: 2;
}

.MoviesRow{
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    width: 130vw;
    height: 60vh;
    padding-top: 30px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.MoviePoster{
    margin: 10px;
    height: 225px;
    width: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    -webkit-animation: slide-right 45s linear both;
    animation: slide-right 45s linear both;
    animation-iteration-count: infinite;

    -webkit-transform: translateX(-130%);
    transform: translateX(-130%);

    -webkit-transition: margin-top .2s ease-out, box-shadow .2s ease-out;
    -moz-transition: margin-top .2s ease-out, box-shadow .2s ease-out;
    -ms-transition: margin-top .2s ease-out, box-shadow .2s ease-out;
    -o-transition: margin-top .2s ease-out, box-shadow .2s ease-out;
    transition: margin-top .2s ease-out, box-shadow .2s ease-out;
}

/*.MoviePoster:hover{*/
    /*margin-top: -5px;*/
    /*box-shadow: 0 10px 15px rgba(0,0,0,.8);*/
/*}*/

@-webkit-keyframes slide-right {
    0% {
    -webkit-transform: translateX(-130%);
    transform: translateX(-130%);
    }
    50% {
    -webkit-transform: translateX(130%);
    transform: translateX(130%);
    }
}
@keyframes slide-right {
    0% {
    -webkit-transform: translateX(-130%);
    transform: translateX(-130%);
    }
    50% {
    -webkit-transform: translateX(130%);
    transform: translateX(130%);
    }
}

.titleDropdown{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 10px 0 25px;
}

.titleDropdown > div{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px){
    .titleDropdown > div{
    width: 100%;
    }
}

.titleDropdown > div > *{
    margin: 0 20px;
}

.titleDropdown > *:last-child{
    margin-left: 20px;
}

select{
    font-size: 25px;
    font-weight: 600;
    margin: 0;
    border: 1px solid black;
    background: transparent;
    text-align: center;
    -webkit-appearance: menulist;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 20px 20px 0;
    background-color: #d9d9d9;
    min-height: 40vh;
    box-shadow: 0 -10px 200px rgba(0,0,0,.8);
    z-index: 100;
}

.search-box{
    display: flex;
    width: 60%;
    align-self: center;
    justify-self: center;
    justify-content: center;
}

.search-box input{
    flex-grow: 2;
    padding: 20px 40px;
    margin: 0 6px;
    font-size: 18px;
    width: 50%;
    min-width: 350px;
    background: #f0f0f0;
    border-radius: 50px;
    border: none;
    box-shadow: 0 0 15px rgba(0,0,0,.13);
    transition: all ease-out .2s;
    z-index: 2 ;
    -webkit-appearance: none;
}

.search-box input:hover{
    box-shadow: 0 0 40px rgba(0,0,0,.2);
}

.search-box input:focus{
    outline: none;
    box-shadow: 0 8px 15px rgba(0,0,0,.25);
    background: white;
}


.search-box *[type="submit"]{
    flex-grow: 1;
    padding: 20px 10px;
    border-radius: 0 50px 50px 0;
    margin-left:-30px;
    min-width: 0;
    width: auto;
    background: radial-gradient(circle, rgb(181, 0, 21) 0%, rgb(52, 0, 5) 80%);
    background-size: 100% 100%;
    background-position: center center;
    z-index: 1;
    font-weight: 700;
    color: white;
    transition: background-size ease-out .25s;
    box-shadow: 0 0 15px rgba(0,0,0,.13);
}

.search-box *[type="submit"]:hover{
    background-size: 200% 100%;
    box-shadow: 0 0 15px rgba(0,0,0,.13);
}

.search-box *[type="submit"]:active,
.search-box *[type="submit"]:focus

{
    background: radial-gradient(circle, rgb(33, 0, 6) 0%, rgb(52, 0, 5) 80%);
    box-shadow: 0 0 15px rgba(0,0,0,.13);
}

button.country {
    display: flex;
    align-items: center;
    font-size: 25px;
    padding: 20px;
    margin: 0 6px;
    border: none;
    border-radius: 50px;
    background: #f0f0f0;
    box-shadow: 0 1px 10px rgba(0,0,0,.18);
    transition: all ease-out .2s;
}

button.country:focus{
    outline: none !important;
}

button.country:hover{
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

button.country .country-selector{
    display: block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #373737;
    border-radius: 100%;
}

button.country.active .country-selector{
    padding: 1px;
    background-color: #33ccb4;
    border-color: #33ccb4;
}

.previews{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 40px 20px;
}

.preview-item{
    display: flex;
    flex-wrap: wrap;
    max-width: 220px;
    margin: 15px;
    border-radius: 30px;
    transition:
    width ease .5s,
    max-width ease .5s,
    height ease .75s;
}

@media (max-width: 768px){
    .preview-item{
    max-width: 150px;

    }
}

.preview-item.active{
    max-width: 1100px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 100px;
    background-color: white;
    box-shadow: 0 8px 10px rgba(0,0,0,.35);
}

.backdrop{
    height: 0;
    width: 100%;
    background-size: cover;
    border-radius: 20px 20px 0 0 ;
    transition-delay: 1s;
    transition: height ease .5s;
}

.preview-item.active .backdrop.slideIn{
    min-height: 300px;
}

.backdrop-gradient{
    display: flex;
    justify-content: flex-end;
    height: 300px;
    width: 100%;
    background: rgba(255,255,255,.4);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 99%);
    border-radius: 20px 20px 0 0 ;
}

.backdrop-gradient button{
    font-size: 40px;
    max-height: 40px;
    line-height: 0;
    margin: 20px 20px 0 0;
    border: none;
    background-color: transparent;
    color: white;
    /*box-shadow: 0 0 4px rgba(0,0,0,.2);*/
    padding: 0 5px;
    text-align: center;
    text-shadow: 0 0 20px black;
}

.preview-item.active .preview-content{
    padding: 0 40px 0;
    background-color: transparent;
    z-index: 0;
}

.preview-item.active .preview-box{
    border-radius: 0 0 20px 20px;
    background-color: white;
    box-shadow: none;
    padding: 30px;
    width: 40%;
    margin-bottom: 30px;
    max-height: 472px;
}

.preview-box {
    min-width: 220px;
    max-width: 100%;
    min-height: 350px;
    box-shadow: 0 0 8px rgba(0,0,0,.2);
    background-size: auto calc(100% + 5px);
    background-position: top center;
    box-sizing: border-box;
    background-repeat: no-repeat;
    transform: translateY(0);
    z-index: 2;
    transition:
    transform ease-out .25s,
    background-position ease .8s,
    width ease .5s,
    height ease .75s;
}

.preview-item:not(.active):hover .preview-box{
    transform: translateY(-10px);
    background-position: bottom center;
}

.preview-content{
    display: flex;
    justify-content: flex-end;
    height: 100%;
    min-height: 350px;
    background-color: rgba(0,0,0,.45);
    z-index: 2;
    transition:
    transform ease-out 1s,
    /*background-color linear .5s;*/
}

.preview-item:not(.active):hover .preview-content{
    background-color: rgba(0,0,0,.05);
}


.information-box p{
    font-size: 18px;
    font-weight: 300;
    color: black;
    transition: all ease-out .8s;
    transition-delay: 1s;
}

.information-box{
    color:black;
    width: calc(60% - 40px);
    text-align: left;
    padding: 0 20px;
}

.information-box p, .information-box h2{
    transition: transform ease-out .5s;
    transform: translateY(0);
    opacity: 0;
}

.preview-item.active .information-box p,
.preview-item.active .information-box h2{
    transform: translateY(-5px);
    opacity: 1;
}


.flags-row{
    width: 100%;
    display: flex;
    padding-top: 4px;
    margin-bottom: 40px;
    overflow-x: scroll;
}

.availability-line{
    display: flex;
    align-items: center;
    padding: 25px 0 15px;
}

.availability-line select{
    font-size: 1.17em;
    padding: 2px 4px;
    max-height: 35px;
    margin-right: 10px;
}

.availability-box{
    display: flex;
    flex-direction: column;
}

.availability-row{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:15px;
    padding: 5px 0;
    border-radius: 10px;
    width: calc(100% - 40px);
    min-height: 40px;
}

.availability-row > div:first-child{
    display: flex;
    width: 100%;
}

 h3{
    margin: 0;
}

h3 span{
    font-size: 14px;
    color: #989898;
    font-weight: 300;
}

/*.availability-row:hover{*/
    /*background-color: #f0f0f0;*/
    /*box-shadow: 0 3px 5px rgba(0,0,0,.15);*/
/*}*/

/*.availability-row:hover .package{*/
    /*background-color: #fafafa;*/
/*}*/

.availability-row img{
    width: 80px;
    margin-left: 0;
    margin-right: 15px;
}

.packages{
    width: 100%;
    display: flex;
    padding-top: 4px;
}

.package{
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    margin: 3px 6px;
    padding: 3px 0;
    border-radius: 5px;
}

.price{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.currency{
    font-size: 10px;
}

.type{
    font-size: 11px;
    text-align: center;
    min-width: 49%;
}

.quality{
    flex-grow: 2;
    font-size: 11px;
    text-align: center;
    width: 49%;
    max-width: 100%;
}

.additional-info{
    color:#989898;
    display: flex;
    justify-content: space-between;
}

.iconRows{
    display: flex;
    flex-wrap: wrap;
}

.offers-box{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.offers-row{
    display: flex;
    align-items: center;
    padding: 5px 20px;
    border-radius: 10px;
    width: calc(100% - 40px);
}

.offers-row:not(:first-child){
    margin-top: 10px;
}

.offers-row:hover{
    background-color: #f0f0f0;
    box-shadow: 0 3px 5px rgba(0,0,0,.15);
}

.offers-row:hover .package{
    background-color: #fafafa;
}

.offers-row img{
    width: 60px;
}

.packages{
    width: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
}

.package{
    display: flex;
    flex-wrap: wrap;
    margin: 6px 6px;
    padding: 3px 0;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0,0,0,.5);
    transition: box-shadow .3s ease-out;
    cursor: pointer;
    text-decoration: none;
    color:black
}


.package:hover{
    background-color: darkred !important;
    box-shadow: 0 2px 5px rgba(0,0,0,.2);
    color: white;
}

.price{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.currency{
    font-size: 10px;
}

.type{
    font-size: 11px;
    text-align: center;
    min-width: 49%;
}

.quality{
    flex-grow: 2;
    font-size: 11px;
    text-align: center;
    width: 49%;
    max-width: 100%;
}

.icon-box{
    margin: 0 10px;
}

.d-none{
    display: none;
}

.loading{
    display: flex;
    margin: 0 5px;
}

.loading span{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    -webkit-animation: loading 1s ease-out;
    animation: loading 1s ease-out;
    animation-iteration-count: infinite;
    margin:0 7px;
    background: red;
    display: block;
    opacity: .3;
}

.loading span:nth-child(2){
    animation-delay: 0;
}

.loading span:nth-child(2){
    animation-delay: .3s;
}

.loading span:nth-child(3){
    animation-delay: .6s;
}

.supportRow{
    background-color: #292929;
    padding: 20px;
    color: white;
    margin: 0;
}

.supportRow a{
    font-weight: 600;
    color: white;
}

.appLinkRow{
    width: 100vw;
    padding: 10px 0;
    display: flex;
    background-color: #d9d9d9;
    justify-content: center;
}

.appLinkRow button{
    font-size: 30px;
    background-color: transparent;
    border-color: transparent;
    position: absolute;
    right: 0;
}

@-webkit-keyframes loading {
    0% {
    opacity: .3;
    }
    49% {
    opacity: .3;
    }
    50% {
    opacity: 1;
    }
}
@keyframes loading {
    0% {
    opacity: .3;
    }
    49% {
    opacity: .3;
    }
    50% {
    opacity: 1;
    }
}

@media(max-width: 992px){
    .search-box{
    width: 100%;
    }

    .search-box input[type="search"]{
    width: 60%;
    min-width: 0;
    padding: 20px 15px;
    border-radius: 10px;
    }

    .search-box input[type="submit"]{
    width: 30%;
    margin-left: -5%;
    border-radius: 10px;
    }
}


@media (max-width: 768px){

    .appLinkRow{
        justify-content: left;
    }

    .appLinkRow p{
        margin-bottom: 5px;
    }

    .appLinkRow a{
        display: block;
        margin-left: 0;
    }

    .appLinkRow img{
        margin-left: 0;
        display: block;
    }

    .header > img{
    max-width: 80%;
    }

    .titleDropdown > *:last-child {
    margin-left: 0;
    }

    .titleDropdown > div > *{
    margin: 3px;
    }

    .mainContainer{
    min-height: 80vh;
    }

    .MoviesHero{
    height: 20vh;
    }

    .MoviesHero > p{
    display: none;
    }

    .MoviesRow{
    display: none;
    }

    .offers-row{
    flex-wrap: wrap;
    background-color: #f0f0f0;
    box-shadow: 0 3px 5px rgba(0,0,0,.15);
    }

    .offers-row img{
    width: 30%;
    margin:7px auto 3px;
    height: auto;
    }

    .packages{
    width: 100%;
    padding-left: 0;
    justify-content: space-evenly;
    }

    .package{
    background-color: #fafafa;
    }

    .mainContainer{
    padding:0 10px;
    }

    .previews{
    padding: 40px 0 0 0 ;
    }

    .preview-item{
    max-width: 120px;
    }

    .previews.active{
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 0;
    z-index: 10;
    }

    .preview-item.active{
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    }

    .preview-item.active,
    .preview-item.active .backdrop{
    border-radius: 0;
    }

    .preview-box{
    min-height:180px;
    max-width: 50%;
    min-width: 120px;
    }

    .preview-content{
    min-height:180px;
    background-color: transparent;
    }

    .preview-item.active .preview-box{
    display: none;
    }

    .preview-item.active .information-box{
    width: calc(100% - 40px)
    }

    .availability-row{
    flex-wrap: wrap;
    height: auto;
    }

    .additional-info{
    flex-wrap: wrap;
    }

    .additional-info span{
    margin-top: 5px;
    width: 100%;
    }

    .availability-line{
    flex-wrap: wrap;
    }

    .availability-line h3{
    margin-top: 10px;
    }

    .availability-row img{
    width: 50%;
    padding: 0 25%;
    }
}